import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'projects/case-manager/src/environments/environment';
import { LoginService } from '../auth/services/login.service';
import { APIRoutes } from '../enums/api-routes.enum';
import { ErrorMessageService } from './error-message.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class SettingService {
  public closeGroupPopper = new BehaviorSubject<boolean>(false);

  public selectedGroupEdit: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  public GroupEdit = new BehaviorSubject<boolean>(false);

  public showAssignedUser: BehaviorSubject<any> = new BehaviorSubject([]);

  public organizations: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public users: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public groups: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public roles: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public permissions: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public group_id: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public choices: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public _config = environment;
  public serverURL = this._config.serverIP;

  constructor(
    private http: HttpClient,
    private _authService: LoginService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  setHeaders(hmacData) {
    let options;
    let user = this.getFromLocal('currentUserCaseMgt');
    if (!!user) {
      let token = user;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
      options = {
        headers: headers,
      };
    }
    return options;
  }

  /**GETTERS */
  getKeys() {
    let credentials: any;
    if (localStorage.getItem('userKey') && localStorage.getItem('secretKey')) {
      credentials = {
        userKey: localStorage.getItem('userKey'),
        secretKey: localStorage.getItem('secretKey'),
      };
    }
    return credentials;
  }

  getFromLocal(item) {
    return localStorage.getItem(item);
  }

  getUserDetails(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        let token = this.getFromLocal('currentUserCaseMgt');
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getUserdetailsAPI,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
              token: token,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getUserdetailsAPI,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      userDetails: response.data.user_details,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting user details for login,, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting user details for login, Please try again later!'
        );
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getRolesAgainstUser(user?: any): Observable<any> {
    let passingData = {};
    if (user) passingData = { user_id: user };
    else passingData = { key: 'value' };

    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.rolesAgainstUser,
            method: 'POST',
            parameters: passingData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.rolesAgainstUser,
                passingData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      userRoles:
                        response.data &&
                        response.data.roles &&
                        response.data.roles.length
                          ? response.data.roles
                          : [],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      'Error in getting roles details against user, Please try again!'
                    );
                  }
                  observer.error({ status: 'error' });
                  observer.complete();
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getRoleDetails(id?): Observable<any> {
    let passingData = {};
    if (id) passingData = { role_id: id };
    else passingData = { key: 'value' };
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.roleDetails,
            method: 'POST',
            parameters: passingData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.roleDetails,
                passingData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      roleDetails:
                        response.data &&
                        response.data.roles &&
                        Object.keys(response.data.roles).length
                          ? response.data.roles
                          : undefined,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting role details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getGroupDetails(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getGroupDetailsById,
            method: 'POST',
            parameters: { id: id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };

            this.http
              .post(
                this.serverURL + APIRoutes.getGroupDetailsById,
                { id: id },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      roleDetails:
                        response.data &&
                        response.data.roles &&
                        Object.keys(response.data.roles).length
                          ? response.data.roles
                          : undefined,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting groups details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getAllOrganizations(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getOrganizations,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getOrganizations,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      organizations: response.data.organization,
                      package: response.data.package,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting organizations, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getLicenseDetails(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getOrganizations,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getOrganizations,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      organizations: response.data.organization,
                      package: response.data.package,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    observer.next({
                      status: 'error',
                      err: 'Something went wrong in getting license details, Please try again later!',
                    });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getChoices(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getChoice,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getChoice,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (response && response.data) {
                    this.choices.next(response.data);
                    observer.next({
                      status: 'ok',
                      choices: this.choices.getValue(),
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting statuses, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getUsers(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listUsers,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.listUsers,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    this.users.next(
                      response.data &&
                        response.data.user_details &&
                        response.data.user_details.length
                        ? response.data.user_details
                        : []
                    );
                    observer.next({
                      status: 'ok',
                      users: this.users.getValue(),
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting users, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getescalateeUsers(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listEscalateeUsers,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.listEscalateeUsers,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      users: response.data,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting users, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getAllUsers(organization_id, group_id?, user_id?): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      let payload = {
        organization_id: organization_id,
      };

      if (group_id) {
        payload['group_id'] = group_id;
      }

      if (user_id) {
        payload['user_id'] = user_id;
      }

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listAllUsers,
            method: 'POST',
            parameters: payload,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.listAllUsers,
                payload,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      user_details:
                        response.data &&
                        response.data.user_details &&
                        response.data.user_details.length
                          ? response.data.user_details
                          : [],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting all users, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  listUsersAgainstGroup(group_id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listGroupUsers,
            method: 'POST',
            parameters: { group: group_id },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            const body = { group: group_id };
            this.http
              .post(
                this.serverURL + APIRoutes.listGroupUsers,
                body,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    observer.next({
                      status: 'ok',
                      users:
                        response.data &&
                        response.data.user_organization_group &&
                        response.data.user_organization_group.length
                          ? response.data.user_organization_group
                          : [],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    observer.next({
                      status: 'error',
                      error:
                        (err && err.error && err.error.detail) ||
                        ' Something went wrong in getting users against group, Please try again later!',
                    });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getGroups(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listGroups,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.listGroups,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    this.groups.next(
                      response.data &&
                        response.data.org_group &&
                        response.data.org_group.length
                        ? response.data.org_group
                        : []
                    );
                    observer.next({
                      status: 'ok',
                      groups: this.groups.getValue(),
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting groups, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getGroupsAgainstUser(user?: any): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      let passingData = {};
      if (user) passingData = { user: user };
      else passingData = { key: 'value' };
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.groupAgainstUser,
            method: 'POST',
            parameters: passingData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.groupAgainstUser,
                passingData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data && response.error == '') {
                    observer.next({
                      status: 'ok',
                      userGroups:
                        response.data &&
                        response.data.user_organization_group &&
                        response.data.user_organization_group.length
                          ? response.data.user_organization_group
                          : [],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  observer.next({
                    status: 'error',
                    permissionError:
                      err.error.error_code == 'H008'
                        ? 'You donot have permission to select group!'
                        : 'No groups against you, ask your admin',
                  });
                  observer.complete();
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getUserPermissions(payload): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.userPermissions,
            method: 'POST',
            parameters: payload,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.userPermissions,
                payload,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  observer.next({
                    status: 'ok',
                    userPermissions: res['data']['roles'],
                  });
                  observer.complete();
                },
                (err) => {
                  observer.next({
                    status: 'error',
                    error: 'Error while getting permissions',
                  });
                  observer.complete();
                }
              );
          });
      } else {
        observer.next({
          status: 'error',
          error: 'Error while getting permissions',
        });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getRoles(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.listRoles,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.listRoles,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    this.roles.next(
                      response.data &&
                        response.data.roles &&
                        response.data.roles.length
                        ? response.data.roles
                        : []
                    );
                    observer.next({
                      status: 'ok',
                      roles: this.roles.getValue(),
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting roles, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  getPermissionsTag(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getPerms,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getPerms,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;
                  if (response && response.data) {
                    this.permissions.next(
                      response.data &&
                        response.data.permission_tags &&
                        response.data.permission_tags.length
                        ? response.data.permission_tags
                        : []
                    );
                    observer.next({
                      status: 'ok',
                      permissions: this.permissions.getValue(),
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting permissions, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  /**ADDERS */
  addUser(formValue): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createUser,
            method: 'POST',
            parameters: formValue,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.createUser,
                formValue,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;

                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      id: subscribedData.data.user_id,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in creating user, Please try again later!'
                    );
                    observer.error({
                      status: 'error',
                      error: subscribedData.error,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in creating user, Please try again later!'
                    );
                    observer.error({
                      status: 'error',
                    });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  addGroup(formValue): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createGroup,
            method: 'POST',
            parameters: formValue,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.createGroup,
                formValue,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      groupdetails: subscribedData.data.org_group,
                    });
                    observer.complete();
                  } else {
                    if (
                      subscribedData.error &&
                      subscribedData.error ==
                        'Can not process further, your group creation limit is reached.'
                    ) {
                      subscribedData.error =
                        'You cannot create group, maximum limit has reached';
                    }
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in creating group, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    let errorMessage = '';
                    if (err.error.error_code == 'H007') {
                      errorMessage = 'Group Name already exists!';
                    } else {
                      errorMessage =
                        'Something went wrong, Please try again later!';
                    }

                    this.errorMessageService.errorMessage.next(errorMessage);
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  addRole(formValue): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createRole,
            method: 'POST',
            parameters: formValue,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.createRole,
                formValue,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      id: subscribedData.data.role,
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in creating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    let errorMessage = '';
                    if (err.error.error_code == 'H007') {
                      errorMessage = 'Role Name already exists!';
                    } else {
                      errorMessage = err.error.error;
                    }
                    this.errorMessageService.errorMessage.next(
                      errorMessage ||
                        'Something went wrong in creating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  deleteUser(obj) {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.editUser + obj.uid,
            method: 'PUT',
            parameters: { status: obj.status },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.editUser + obj.uid,
                { status: obj.status },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    this.getUsers().subscribe((res) => {
                      if (res) {
                        observer.next({ status: 'ok' });
                        observer.complete();
                      }
                    });
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in deleting user, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in deleting user, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  /**UPDATERS */
  updateUser(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.editUser + obj.uid,
            method: 'PUT',
            parameters: {
              first_name: obj.first_name,
              last_name: obj.last_name,
              user_type: obj.user_type,
              status: obj.status,
            },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.editUser + obj.uid,
                {
                  first_name: obj.first_name.trim(),
                  last_name: obj.last_name.trim(),
                  user_type: obj.user_type.trim(),
                  status: obj.status.trim(),
                },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    this.getUsers().subscribe((res) => {
                      if (res) {
                        observer.next({ status: 'ok' });
                        observer.complete();
                      }
                    });
                  } else {
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in updating user, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  /** Get user by id */
  getUserByID(payload): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getUserByID,
            method: 'POST',
            parameters: payload,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getUserByID,
                payload,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  if (response && response['data']) {
                    observer.next({
                      status: 'ok',
                      userDetails: response['data'],
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in getting user details, Please try again later!'
                    );
                    observer.error({
                      status: 'error',
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting user details, Please try again later!'
                    );
                    observer.error({
                      status: 'error',
                    });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  updateGroup(obj, id): Observable<any> {
    if (!obj.id) obj.id = id;
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.editGroup,
            method: 'PUT',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.editGroup,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({ status: 'ok' });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in deleting group, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in deleting group, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  /** Get group by id */
  getGroupByID(payload): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getGroupByID,
            method: 'POST',
            parameters: payload,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getGroupByID,
                payload,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  if (response && response['data']) {
                    observer.next({
                      status: 'ok',
                      groupDetails: response['data'],
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      'Something went wrong in getting group details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in getting group details, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  updateRole(obj, id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.editRole + id,
            method: 'PUT',
            parameters: {
              name: obj.name,
              status: obj.statusEditRole,
              permission_tag: obj.permTagsEditRole,
            },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.editRole + id,
                {
                  name: obj.name,
                  status: obj.statusEditRole,
                  permission_tag: obj.permTagsEditRole,
                },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    subscribedData.error == ''
                  ) {
                    observer.next({ status: 'ok' });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in updating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in updating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  deleteRoleService(obj, id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.editRole + id,
            method: 'PUT',
            parameters: { status: obj.statusEditRole },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .put(
                this.serverURL + APIRoutes.editRole + id,
                {
                  status: obj.statusEditRole,
                },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    subscribedData.error == ''
                  ) {
                    observer.next({ status: 'ok' });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in deleting / updating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in deleting / updating role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  /**ASSIGNERS */
  groupAssignment(uid, gid, assignment): Observable<any> {
    let inputData = {
      group: gid,
      user: uid,
      assign: assignment,
    };
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.assignGroupAPI,
            method: 'POST',
            parameters: inputData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.assignGroupAPI,
                inputData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    !subscribedData.error
                  ) {
                    observer.next({
                      status: 'ok',
                      alreadyExistsUser:
                        subscribedData?.data?.rejected_users?.join(','),
                    });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in assignment of group, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in assignment of group, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }

  roleAssignment(id, role, assignment): Observable<any> {
    let inputData = {
      role_id: role,
      user_id: id,
      assign: assignment,
    };

    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.assignRoleAPI,
            method: 'POST',
            parameters: inputData,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.assignRoleAPI,
                inputData,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;

                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    !subscribedData.error
                  ) {
                    observer.next({ status: 'ok' });
                    observer.complete();
                  } else {
                    this.errorMessageService.errorMessage.next(
                      subscribedData.error ||
                        'Something went wrong in assignment of role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessageService.errorMessage.next(
                      err?.error?.error ||
                        'Something went wrong in assignment of role, Please try again later!'
                    );
                    observer.error({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info('Session Expired!');
      }
    });
  }
}
